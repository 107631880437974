<template>
	<v-layout v-if="total > 1" shrink align-center ml-2>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn :disabled="documentIndex === 0" icon v-on="on" @click="previous()">
					<v-icon>chevron_left</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.preview.previous'" />
		</v-tooltip>
		<v-flex v-text="model + 1 + '/' + total" />
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn :disabled="documentIndex === total - 1" icon v-on="on" @click="next()">
					<v-icon>chevron_right</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.preview.next'" />
		</v-tooltip>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import { mapGetters } from "vuex";

export default {
	name: 'DocumentsNavigation',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		total: {
			type: Number,
			required: true,
			validator: value => {
				return value >= 0
			}
		},
		value: {
			type: Number,
			required: true,
			validator: value => {
				return value >= 0
			}
		}
	},
	computed: {
		...mapGetters({
			previewHasPreventedKeyboardKey: 'documents/preview/hasPreventKeyboardKey'
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		documentIndex: function () {
			return this.model
		}
	},
	methods: {
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					keyboardEvent: this.keyboard.events.keyDown,
					actions: [
						{
							key: this.keyboard.arrow.left,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.left)) {
									return
								}

								this.previous()
							}
						},
						{
							key: this.keyboard.arrow.right,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.right)) {
									return
								}

								this.next()
							}
						}
					]
				}
			]
		},
		previous: function () {
			this.eventBus.emit(this.events.preview.PREVIOUS_DOCUMENT)
		},
		next: function () {
			this.eventBus.emit(this.events.preview.NEXT_DOCUMENT)
		}
	}
}
</script>
